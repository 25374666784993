import GenericClass from "@/models/generic-class";

export default class StudyQuestion extends GenericClass {
	index ; //number;
	idStudy; // number;
	idStudyQuestion; // number;
	idArtwork; // number;
	mandatory; // boolean;
	order; // number;
	active; // boolean;
	answers; // Array<StudyQuestionAnswer>;
	visitAnswers; // {};
	localizations; // Array<{}>;
	locale; // {};

	constructor(index, idStudy, idStudyQuestion, idArtwork, mandatory, order, active, answers, localizations) {
		super();
		this.index = index;
		this.idStudy = idStudy;
		this.idStudyQuestion = idStudyQuestion;
		this.idArtwork = idArtwork;
		this.mandatory = mandatory;
		this.order = order;
		this.active = active;
		this.answers = answers;
		this.localizations = localizations;
		this.visitAnswers = {};
		this.locale = null;
	}
}
