import GenericClass from "@/models/generic-class";

export const InputType = {
	TEXT: 'TEXT',
	SELECT: 'SELECT',
	MULTIPLE: 'MULTIPLE',
	DATE: 'DATE',
	RATING: 'RATING'
};

export default class StudyQuestionAnswer extends GenericClass {
	idStudyQuestionAnswer; // number;
	idStudyQuestion; // number:
	order; // number;
	type; // InputType;
	localizations; // Array<{}>;
	locale; // {};
	options; // Array<{}>;

	constructor(idStudyQuestionAnswer, idStudyQuestion, order, type, localizations, options) {
		super();
		this.idStudyQuestionAnswer = idStudyQuestionAnswer;
		this.idStudyQuestion = idStudyQuestion;
		this.order = order;
		this.type = type;
		this.localizations = localizations;
		this.locale = null;
		this.options = options;
	}
}
