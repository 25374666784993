import GenericClass from "@/models/generic-class";

export default class VisitStudyQuestionAnswer extends GenericClass {
	idVisitStudyQuestionAnswer; // number;
	idStudyQuestionAnswer; // number;
	idVisit; // number;
	value; // string;

	constructor(idVisitStudyQuestionAnswer, idStudyQuestionAnswer, idVisit, value) {
		super();
		this.idVisitStudyQuestionAnswer = idVisitStudyQuestionAnswer;
		this.idStudyQuestionAnswer = idStudyQuestionAnswer;
		this.idVisit = idVisit;
		this.value = value;
	}
}
